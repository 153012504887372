// App Access Token
export const APP_ACCESS_TOKEN_KEY_NAME = 'appAccessToken'

// User Access Token
export const USER_ACCESS_TOKEN_KEY_NAME = 'userAccessToken'

// User Refresh Token
export const USER_REFRESH_TOKEN_KEY_NAME = 'userRefreshToken'

// Admin App Access Token
export const APP_ADMIN_ACCESS_TOKEN_KEY_NAME = 'appAdminAccessToken'

// Admin Access Token
export const ADMIN_ACCESS_TOKEN_KEY_NAME = 'adminAccessToken'

// Admin Refresh Token
export const ADMIN_REFRESH_TOKEN_KEY_NAME = 'adminRefreshToken'

// User Notification After Login
export const NOTIFICATION_KEY_NAME = 'notification'
